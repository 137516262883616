import Container from './container'
import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'

export default function PageHeading({heading}) {
  return (
    <StyledComponent className="page-heading">
      <Fade>
        <Container type="wide" className="page-heading-container">
          <h2>{heading}</h2>
        </Container>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin-bottom: 2rem;

  @media (min-width: 375px) {
    margin-bottom: 2.5rem;
  }

  @media (min-width: 700px) {
    margin-bottom: 3rem;
  }

  @media (min-width: 1000px) {
    margin-bottom: 4rem;
  }

  @media (min-width: 1200px) {
    padding-top: 0;
  }

  .page-heading-container {
    @media (min-width: 1200px) {
      padding: 0 5rem;
    }
  }

  h2 {
    background-color: #000;
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    font-weight: 200;
    line-height: 1.2;
    margin: 0 0 0 -2rem;
    min-width: 25rem;
    padding: 1.2rem 2rem;
    text-transform: uppercase;

    @media (min-width: 375px) {
      font-size: 2.3rem;
      min-width: 28rem;
    }

    @media (min-width: 1200px) {
      margin-left: -5rem;
    }
  }
}`
