import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'

export default function SubHeading({heading, paddingLeft = false}) {
  return (
    <StyledComponent className="sub-heading">
      <Fade>
        <h3>{heading}</h3>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin-bottom: 2rem;

  @media (min-width: 375px) {
    margin-bottom: 2.5rem;
  }

  @media (min-width: 700px) {
    margin-bottom: 3rem;
  }

  @media (min-width: 1000px) {
    margin-bottom: 4rem;
  }

  @media (min-width: 1200px) {
    padding-top: 0;
  }

  .page-heading-container {
    @media (min-width: 1200px) {
      padding: 0 5rem;
    }
  }

  h3 {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: ${props => (props.paddingLeft ? '0 0 0 1rem' : '0 0 0 0')};
    text-transform: uppercase;

    @media (min-width: 375px) {
      font-size: 2.3rem;
    }
  }
}`
