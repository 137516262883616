import {graphql, useStaticQuery} from 'gatsby'
import {Link as ScrollLink} from 'react-scroll'
import DownArrowSvg from './down-arrow-svg'
import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'
import {HOME_PAGE_SCROLL_DOWN_DURATION_MS} from './../constants/global-constants'

export default function HoldingPageHeroImage() {
  const data = useStaticQuery(graphql`
    {
      prismicHoldingPage {
        data {
          hero_image_mobile {
            url
          }
          hero_image_tablet {
            url
          }
          hero_image_desktop {
            url
          }
          hero_image_text {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    heroImageMobileUrl: data.prismicHoldingPage.data.hero_image_mobile.url,
    heroImageTabletUrl: data.prismicHoldingPage.data.hero_image_tablet.url,
    heroImageDesktopUrl: data.prismicHoldingPage.data.hero_image_desktop.url,
    heroImageText: data.prismicHoldingPage.data.hero_image_text.text,
  }

  return (
    <StyledComponent
      className="holding-page-hero-image"
      heroImageMobileUrl={prismicData.heroImageMobileUrl}
      heroImageTabletUrl={prismicData.heroImageTabletUrl}
      heroImageDesktopUrl={prismicData.heroImageDesktopUrl}
    >
      <Fade delay={500}>
        <div className="header-holding-hero-image"></div>
      </Fade>
      <Fade delay={1000}>
        <p className="header-holding-hero-text" dangerouslySetInnerHTML={{__html: prismicData.heroImageText}} />
      </Fade>
      <div className="scroll-btn-wrapper">
        <Fade delay={1500}>
          <ScrollLink
            to="contact"
            className="scroll-btn"
            spy={true}
            smooth={true}
            offset={0}
            duration={HOME_PAGE_SCROLL_DOWN_DURATION_MS}
          >
            <span className="sr-only">Scroll down</span>
            <DownArrowSvg className="down-arrow-svg" fill="#fff" />
          </ScrollLink>
        </Fade>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  color: #fff;
  height: calc(100vh - 5rem - 17.6rem);
  position: relative;

  @media (min-width: 570px) {
    height: calc(100vh - 5rem - 14.6rem);
  }

  @media (min-width: 1200px) {
    height: calc(100vh - 0rem - 9.6rem);
  }

  .header-holding-hero-wrapper {
    color: #fff;
    height: 100%;
    position: relative;
  }
  .header-holding-hero-image {
    background-color: #808080;
    background-image: url(${props => props.heroImageMobileUrl});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @media (min-width: 700px) {
      background-image: url(${props => props.heroImageTabletUrl});
    }
    @media (min-width: 1200px) {
      background-image: url(${props => props.heroImageDesktopUrl});
    }

    &::after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 7rem;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }
  .header-holding-hero-text {
    font-size: 1rem;
    text-align: center;

    bottom: 1rem;
    font-weight: 300;
    letter-spacing: 0.3rem;
    position: absolute;
    right: 1rem;
    text-align: right;
    text-transform: uppercase;
    z-index: 2;
    margin-bottom: 0;

    @media (min-width: 340px) {
      font-size: 1.1rem;
    }
    @media (min-width: 355px) {
      font-size: 1.2rem;
    }
    /* @media (min-width: 370px) {
      font-size: 1.5rem;
    } */

    span {
      font-weight: 700;
    }
  }
  .scroll-btn-wrapper {
    animation: scrollDownArrowAnimation 4s linear 0s infinite normal none;
    bottom: 5rem;
    display: block;
    height: auto;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 5.5rem;
    z-index: 10;

    @media (min-width: 768px) {
      bottom: 8.5rem;
    }

    @media (min-width: 900px) {
      bottom: 5rem;
    }
  }
  .scroll-btn {
    color: #fff;
    cursor: pointer;
    display: block;
    height: auto;
    width: 5.5rem;

    @media (min-width: 800px) {
      width: 5.5rem;
    }

    @media (min-width: 1000px) {
      width: 5.5rem;
    }
  }
`
