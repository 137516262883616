import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import PageHeading from './page-heading'
import React from 'react'
import styled from 'styled-components'
import SubHeading from './sub-heading'
import TextBlock from './text-block'

export default function ContactSection({pageName, cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicContactPage {
        data {
          intro_text {
            text
          }
          page_heading {
            text
          }
          phone_prompt {
            text
          }
          location_heading {
            text
          }
          location_intro_text {
            html
          }
          address {
            html
          }
          google_maps_link {
            url
          }
          map {
            alt
            url(imgixParams: {q: 80, auto: "format"})
            dimensions {
              height
              width
            }
          }
          google_maps_embed_code {
            html
            text
          }
          barn_picture {
            thumbnails {
              mobile {
                alt
                dimensions {
                  width
                  height
                }
                url
              }
              tablet {
                alt
                dimensions {
                  width
                  height
                }
                url
              }
              desktop {
                alt
                dimensions {
                  width
                  height
                }
                url
              }
            }
          }
        }
      }
    }
  `)

  const prismicData = {
    address: data.prismicContactPage.data.address.html,
    googleMapsLink: data.prismicContactPage.data.google_maps_link.url,
    introText: data.prismicContactPage.data.intro_text.text,
    locationHeading: data.prismicContactPage.data.location_heading.text,
    locationIntroText: data.prismicContactPage.data.location_intro_text.html,
    mapImageAlt: data.prismicContactPage.data.map.alt,
    mapImageHeight: data.prismicContactPage.data.map.dimensions?.height,
    mapImageUrl: data.prismicContactPage.data.map.url,
    mapImageWidth: data.prismicContactPage.data.map.dimensions?.width,
    pageHeading: data.prismicContactPage.data.page_heading.text,
    phonePrompt: data.prismicContactPage.data.phone_prompt.text,
    googleMapsEmbedCodeText: data.prismicContactPage.data.google_maps_embed_code?.text,
    barnPictureMobile: data.prismicContactPage.data.barn_picture.thumbnails.mobile,
    barnPictureTablet: data.prismicContactPage.data.barn_picture.thumbnails.tablet,
    barnPictureDesktop: data.prismicContactPage.data.barn_picture.thumbnails.desktop,
  }

  return (
    <>
      <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
        <PageHeading heading={prismicData.pageHeading} />

        <Container type="wide">
          <StyledForm
            className="form--contact"
            name="Ali Hearn Website Contact Form"
            method="POST"
            action="/contact-thanks/"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <Fade>
              <div className="intro-text">
                <TextBlock>
                  <p>{prismicData.introText}</p>
                </TextBlock>
              </div>
            </Fade>

            <input type="hidden" name="form-name" value="Ali Hearn Website Contact Form" />
            <input type="hidden" name="bot-field" />
            <div className="form-row form-row--name-email">
              <div className="form-item form-item--name">
                <Fade>
                  <label className="form-item__label" htmlFor="name">
                    Name *
                  </label>
                </Fade>

                <Fade>
                  <input className="form-item__input" type="text" id="name" name="name" autoComplete="name" required />
                </Fade>
              </div>
              <div className="form-item form-item--email">
                <Fade>
                  <label className="form-item__label" htmlFor="email">
                    Email *
                  </label>
                </Fade>

                <Fade>
                  <input className="form-item__input" type="email" id="email" name="email" autoComplete="email" required />
                </Fade>
              </div>
            </div>
            <div className="form-row form-row--telephone-message">
              <div className="form-item form-item--telephone">
                <Fade>
                  <label className="form-item__label" htmlFor="telephone">
                    Telephone
                  </label>
                </Fade>

                <Fade>
                  <input
                    className="form-item__input"
                    type="text"
                    id="telephone"
                    name="telephone"
                    autoComplete="tel"
                    placeholder="OPTIONAL"
                  />
                </Fade>
              </div>
              <div className="form-item form-item--message">
                <Fade>
                  <label className="form-item__label" htmlFor="message">
                    Message *
                  </label>
                </Fade>

                <Fade>
                  <textarea className="form-item__textarea" id="message" name="message" autoComplete="off" required />
                </Fade>
              </div>
            </div>
            <div className="form-row form-row--submit">
              <Fade>
                <button type="submit" className="btn btn--primary">
                  Send
                </button>
              </Fade>
            </div>
            <Fade>
              <p className="phone-prompt">{prismicData.phonePrompt}</p>
            </Fade>
          </StyledForm>
        </Container>
      </StyledComponent>
      <StyledLocationSection>
        <Container type="wide">
          <div className="location-wrapper">
            <SubHeading heading={prismicData.locationHeading} paddingLeft={false} />
            {prismicData.locationIntroText && (
              <div className="location-intro-text-wrapper">
                <Fade>
                  <TextBlock>{<div dangerouslySetInnerHTML={{__html: prismicData.locationIntroText}} />}</TextBlock>
                </Fade>
              </div>
            )}

            {prismicData.barnPictureMobile.url && prismicData.barnPictureTablet.url && prismicData.barnPictureDesktop.url && (
              <div className="location-barn-picture-wrapper">
                <Fade>
                  <picture className="barn-picture">
                    <source media="(min-width: 900px)" srcSet={prismicData.barnPictureDesktop.url} />
                    <source media="(min-width: 600px)" srcSet={prismicData.barnPictureTablet.url} />
                    <source media="(min-width: 0px)" srcSet={prismicData.barnPictureMobile.url} />
                    <img
                      src={prismicData.barnPictureTablet.url}
                      alt={prismicData.barnPictureTablet.alt}
                      width={prismicData.barnPictureTablet.dimensions?.width}
                      height={prismicData.barnPictureTablet.dimensions?.height}
                    />
                  </picture>
                </Fade>
              </div>
            )}

            <div className="address-map-wrapper">
              <Fade>
                <div className="address" dangerouslySetInnerHTML={{__html: prismicData.address}} />
              </Fade>
              <Fade>
                <div className="map">
                  {prismicData.googleMapsEmbedCodeText ? (
                    <div className="google-map-wrapper" dangerouslySetInnerHTML={{__html: prismicData.googleMapsEmbedCodeText}} />
                  ) : (
                    <img
                      className="map-image"
                      src={prismicData.mapImageUrl}
                      alt={prismicData.mapImageAlt}
                      width={prismicData.mapImageWidth}
                      height={prismicData.mapImageHeight}
                    />
                  )}
                  <a href={prismicData.googleMapsLink} target="_blank" className="google-maps-link">
                    View on Google Maps
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </Container>
      </StyledLocationSection>
    </>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;

  .intro-text {
    max-width: 70rem;
    margin: 0 0 2rem 0;
  }
`

const StyledForm = styled.form`
  margin-bottom: 0;
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;

  .form-item {
    @media (min-width: 800px) {
      width: 100%;
    }

    &--name,
    &--message {
      @media (min-width: 800px) {
        margin-right: 6rem;
      }
    }

    &--name,
    &--telephone {
      margin-bottom: 3rem;
    }

    &__label {
      display: block;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
      max-width: 30rem;
    }

    &__input {
      border: 1px solid #e1e1e1;
      background-color: #ffffff;
      font-size: 1.4rem;

      &:focus {
        outline: 1px solid black;
        outline-offset: 2px;
      }
    }

    &__textarea {
      border: 1px solid #e1e1e1;
      background-color: #ffffff;
      font-size: 1.4rem;
      height: 20.5rem;
      max-height: 70rem;
      min-height: 20rem;
      height: 20rem;
      resize: vertical;

      &:focus {
        outline: 1px solid black;
        outline-offset: 2px;
      }
    }
  }

  .form-row {
    max-width: 1300px;

    @media (min-width: 800px) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &--telephone-message {
      @media (min-width: 800px) {
        flex-direction: row-reverse;
      }
    }

    &--submit {
      @media (min-width: 600px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      button {
        display: block;
        font-weight: 700;
        min-width: 11rem;
        width: 100%;
        height: 50px;

        @media (min-width: 600px) {
          width: auto;
        }
      }
    }
  }
`

const StyledLocationSection = styled.section`
  .location-wrapper {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
  }

  .location-intro-text-wrapper {
    margin-bottom: 4rem;
    max-width: 87rem;
  }

  .location-barn-picture-wrapper {
    margin-bottom: 4rem;
  }

  .barn-picture {
    display: block;
    width: 100%;
    height: auto;
  }

  .address-map-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media (min-width: 900px) {
      flex-direction: row;
    }

    .address {
      flex-shrink: 0;
    }

    .map {
      width: 100%;
    }

    .google-map-wrapper {
      aspect-ratio: 1000/625;
      background-color: #e1dfdc;
      margin: 0 0 1rem 0;
      position: relative;

      > iframe {
        bottom: 0;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }

    .map-image {
      margin-bottom: 1rem;
    }

    .google-maps-link {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
`
